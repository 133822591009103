import { useEffect } from "react";
import '../App.scss';
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const list_anime = {
    hidden: {
        delay: 0,
        opacity: 0,
        staggerDirection: -1,
    },
    visible: {
        delay: 5,
        opacity: 1,
        transition: {
            delayChildren: .5,
            staggerChildren: .5,
        },
    },
};



const item_anime = {
    hidden: {
        opacity: 0,
        translateY: -10,
    },
    visible: {
        opacity: 1,
        translateY: 0,
    }
}

const Terms = () => {

    const animation = useAnimation();
    const { ref, inView } = useInView({ threshold: 0.1 });


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)

        if (inView) {
            animation.start("visible");
        } else {
            animation.start("hidden");
        }
    }, [animation, inView]);

    return (
        <>
            <motion.div

                ref={ref}
                id="Terms" className="terms-section">

                <motion.div
                    variants={list_anime}

                    className="terms-list-wrapper">

                    <motion.h1
                        variants={item_anime}
                        key="header-1"
                        className="page-title mb-10">Terms & Conditions</motion.h1>

                    <motion.p
                        variants={item_anime}
                        key="txt-1"
                        className="def-txt mb-20">This Zugaikotsu NFT License Agreement (the “License”) is a legally binding agreement entered into between Zugaikotsu NFT and you. When you acquire a Zugaikotsu NFT, you own all personal property rights to the token underlying the Zugaikotsu NFT (e.g., the right to freely sell, transfer, or otherwise dispose of that Zugaikotsu NFT), but you do not own the associated artwork, brand, or other intellectual property associated with that Zugaikotsu NFT, except for the specific licensed rights set forth below.</motion.p>


                    <motion.h1
                        variants={item_anime}
                        key="definitions-1"
                        className="page-title mb-10">DEFINITIONS</motion.h1>



                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5"><b>“Zugaikotsu NFT”</b> means any Zugaikotsu branded or themed NFT created by or on behalf of Zugaikotsu NFT.</motion.p>
                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5"><b>“Zugaikotsu NFT Art” </b>

                        means the artwork, image or works of authorship associated with a specific Zugaikotsu NFT.</motion.p>
                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5"><b>“Zugaikotsu Trademarks” </b>
                        means any and all logos, trademarks, service marks, and trade dress associated with Zugaikotsu, Zugaikotsu.com, the Zugaikotsu NFTs, including the “Zugaikotsu” or “Zugaikotsu.com” names, or any other names of Zugaikotsu-related characters or products or service developed by us.
                    </motion.p>
                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5"><b>“Zugaikotsu,” “us,” “we,” or “our”</b>
                        means Zugaikotsu NFT project and/or its affiliates.
                        means any and all logos, trademarks, service marks, and trade dress associated with Zugaikotsu, Zugaikotsu.com, the Zugaikotsu NFTs, including the “Zugaikotsu” or “Zugaikotsu.com” names, or any other names of Zugaikotsu-related characters or products or service developed by us.
                    </motion.p>
                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5"><b>“Commercial Use”</b>
                        means any activity that is performed with the intent to generate revenue, such as sale or transfer of items (including NFTs) on any marketplace, creating and selling merchandise or creating a comic book or video game.
                    </motion.p>
                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5"><b>“NFT”</b>
                        means any blockchain-tracked non-fungible token, including those complying with the ERC-721A, ERC-721, ERC-1155, or other non-fungible token standard.
                    </motion.p>
                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5"><b>“NFT”</b>
                        means any blockchain-tracked non-fungible token, including those complying with the ERC-721A, ERC-721, ERC-1155, or other non-fungible token standard.
                    </motion.p>
                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5"><b>“you” or “your”</b>
                        means a lawful owner of a Zugaikotsu NFT.
                    </motion.p>
                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-20">
                        Capitalized terms that you see have the meanings set forth in this “Definitions” Section.                    </motion.p>



                    <motion.h1
                        variants={item_anime}
                        key="lisense-1"
                        className="page-title mb-10">LICENSE GRANT</motion.h1>

                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5">
                        Zugaikotsu NFT Art License. For as long as you lawfully own your Zugaikotsu NFT, and subject to your compliance with the terms of this License, we hereby grant you the following rights:
                    </motion.p>


                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5 pl-30">
                        <b>1.</b> a non-exclusive, worldwide, royalty-free, license to use, reproduce, display, modify, and create derivative works of the Zugaikotsu NFT Art for your Zugaikotsu NFT for personal, non-commercial use (such as to display in your wallet, as a profile picture, to create a pixel-art version for use in a third party platform);
                    </motion.p>
                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-20 pl-30">
                        <b>2.</b>
                        a non-exclusive, worldwide, royalty-free, sub-licensable (but only to service providers assisting you with a Commercial Use) license to use, copy, reproduce and display the Zugaikotsu NFT Art for your Zugaikotsu NFT for any Commercial Use. The license in this sub-section (2) does not include a right to create derivative works of the Zugaikotsu NFT Art except as necessary to adapt and depict the Zugaikotsu NFT Art in or on the goods or media created pursuant to this license, or as otherwise required in the “No Rights to Trademarks” Section below. But this license does permit you to mint and create new NFTs and NFT projects based on your Zugaikotsu NFT Art for Zugaikotsu NFTs that you own, as long as you don't use the Zugaikotsu Trademarks on, or to promote, such new NFTs. From time to time, we may collaborate with third parties to create Zugaikotsu NFTs which include artwork, images, works of authorship, logos, trademarks, service marks, or trade dress owned by a third party (“Third Party Content”). The license in this paragraph does not extend to any Zugaikotsu NFT or Zugaikotsu NFT Art that contains Third Party Content, and you may not use, copy, reproduce, display, create derivative works of, or create new NFTs based on such Third Party Content, or any portion thereof, for any Commercial Use, unless we or the applicable third parties expressly provide our consent in writing or by public announcement.
                    </motion.p>


                    <motion.h1
                        variants={item_anime}
                        key="lisense-1"
                        className="page-title mb-10">MODIFICATIONS AND DERIVATIVE WORKS</motion.h1>


                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5">
                        We recognize that you might want to create modifications and derivative works of your Zugaikotsu NFT Art, and we allow you to do so under the scope of the licenses granted above. However, you acknowledge and agree that Zugaikotsu Founder(s) may also modify, create derivative works of, and update any Zugaikotsu NFT Art and may create works of authorship similar or identical to your own adaptations, derivative works, and modifications of any Zugaikotsu NFT Art. Accordingly, on behalf of yourself and your heirs, successors and assigns, you irrevocably covenant and agree not to assert or bring any suit, claim, demand or challenge against Zugaikotsu, Zugaikotsu Founder(s) or its affiliates or licensees in connection with their use of any Zugaikotsu NFT Art or any adaptations, derivative works, and modifications thereto, even if such artwork or content is similar to or the same as any adaptations, derivative works, or modifications in any Zugaikotsu NFT Art that have been created by you.
                    </motion.p>
                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5">
                        No Rights to Trademarks. Nothing in this License will be interpreted to grant you any rights to any Zugaikotsu Trademarks belonging to Zugaikotsu and Zugaikotsu Founders. Without our written permission, you may not use any Zugaikotsu Trademarks for any Commercial Use, including to register any domain names or social media accounts using any Zugaikotsu Trademarks. This includes any Zugaikotsu Trademarks that may be displayed or contained in any Zugaikotsu NFT Art for your Zugaikotsu NFT (and you will need to modify the Zugaikotsu NFT Art to remove or obfuscate such Zugaikotsu Trademarks before making any Commercial Use of such Zugaikotsu NFT Art). You may not remove, delete or obscure any trademark notice, copyright notice or other intellectual property notice in any Zugaikotsu NFT or Zugaikotsu NFT Art.

                    </motion.p>
                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5">
                        Transfer. The licenses granted above are non-transferrable, except that if you sell or transfer your Zugaikotsu NFT, you will no longer be granted the foregoing licenses in the Zugaikotsu NFT Art, and such license will transfer to the new owner of the Zugaikotsu NFT associated with such Zugaikotsu NFT Art. In connection with any sales, transfers or similar transactions of the Zugaikotsu NFTs, the transferee agrees that by purchasing, accepting, or otherwise acquiring the Zugaikotsu NFT, they shall be deemed to accept the terms of this License. You may not transfer an Zugaikotsu NFT to a transferee that is located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. government as a terrorist-supporting country; or is otherwise listed on any U.S. Government list of prohibited or restricted parties.
                    </motion.p>
                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-5">
                        Restrictions. Notwithstanding any of the above, you may not use the Zugaikotsu NFT Art in any way that constitutes unlawful, defamatory, harassing, abusive, fraudulent, racist, hateful, vulgar, cruel, illegal or obscene activity, or that promotes any such activity.
                    </motion.p>

                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-20">
                        Reservation of Rights. All rights in and to the Zugaikotsu NFT Art and any other intellectual property of Zugaikotsu and it’s founders not expressly licensed herein are hereby reserved by Zugaikotsu. All goodwill arising from any use of the Zugaikotsu Trademarks will inure solely to Zugaikotsu and its affiliates.

                    </motion.p>



                    <motion.h1
                        variants={item_anime}
                        key="lisense-1"
                        className="page-title mb-10">DISCLAIMERS, LIMITATIONS OF LIABILITY, AND INDEMNIFICATION</motion.h1>


                    <motion.p
                        variants={item_anime}
                        className="def-txt mb-100">
                        Disclaimers. YOUR ACCESS TO AND USE OF THE ZUGAIKOTSU NFT AND ZUGAIKOTSU NFT ART IS AT YOUR OWN RISK. YOU UNDERSTAND AND AGREE THAT THE ZUGAIKOTSU NFTS AND ZUGAIKOTSU NFT ART ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. WITHOUT LIMITING THE FOREGOING, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, ZUGAIKOTSU, ITS PARENTS, AFFILIATES, RELATED COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS AND LICENSORS (THE “ZUGAIKOTSU ENTITIES”) DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. THE ZUGAIKOTSU ENTITIES MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM ALL RESPONSIBILITY AND LIABILITY FOR: (A) THE COMPLETENESS, ACCURACY, AVAILABILITY, TIMELINESS, ORIGINALITY, SECURITY OR RELIABILITY OF THE ZUGAIKOTSU NFTS AND ZUGAIKOTSU NFT ART; (B) THE OPERATION OR COMPATIBILITY WITH ANY OTHER APPLICATION OR ANY PARTICULAR SYSTEM, DEVICE, BLOCKCHAIN, DIGITAL WALLET, HARDWARE OR MARKETPLACE; AND (C) WHETHER THE ZUGAIKOTSU NFTS AND ZUGAIKOTSU NFT ART WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE OR ERROR-FREE BASIS; AND (D) THE DELETION OF, OR THE FAILURE TO STORE OR TRANSMIT THE ZUGAIKOTSU NFTS AND ZUGAIKOTSU NFT ART. THE ZUGAIKOTSU NFTS ARE INTENDED FOR CONSUMER ENJOYMENT, USE AND CONSUMPTION ONLY. THEY ARE NOT A “SECURITY,” AS DEFINED UNDER THE SECURITIES ACT OF 1933, AS AMENDED, THE SECURITIES EXCHANGE ACT OF 1934, AS AMENDED, THE INVESTMENT COMPANY ACT OF 1940, AS AMENDED, OR UNDER THE SECURITIES LAWS OF ANY U.S. STATE.
                    </motion.p>

                </motion.div>

            </motion.div>
        </>
    );
}

export default Terms;
