import { useEffect } from "react";
import '../App.scss';
import { FaTwitter, FaDiscord, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useStore } from "../modules/store";

const header = {
    visible: {
        opacity: 1,
        translateY: 0,
        transition: {
            duration: 1,
        },
    },
    hidden: {
        opacity: 0,
        translateY: -30,
    },
};
const footerDetAnime = {
    visible: {
        opacity: 1,
        translateY: 0,
        transition: {
            duration: 1,
            delay: .3,
        },
    },
    hidden: {
        opacity: 0,
        translateY: -20,
    },
};


const listAnime = {
    visible: {
        opacity: 1,
        transition: {
            duration: 1,
            delay: 1,
            staggerChildren: .5,
        },
    },
    hidden: {
        opacity: 0,
        staggerDirection: -1
    },
};

const item = {
    hidden: {
        opacity: 0,
        translateY: 100,
    },
    visible: {
        opacity: 1,
        translateY: 0,
    }
}

function Footer() {
    const links = ["Home", "Gallery", "About", "Roadmap", "Terms"];

    const {
        DISCORD, TWITTER, INSTAGRAM, OPENSEA
    } = useStore();
    const animation = useAnimation();
    const { ref, inView } = useInView({ threshold: 0.1 });

    useEffect(() => {
        if (inView) {
            animation.start("visible");
        } else {
            animation.start("hidden");
        }
    }, [animation, inView]);

    return (
        <motion.footer
            ref={ref}
            animate={animation}
            initial="hidden"
            variants={listAnime}

        >
            <div className="footer-top-wrapper">

                <motion.img
                    animate={animation}
                    initial="hidden"
                    variants={header}
                    src="/logo_letter.svg" className="footer-logo" alt="Zugaikotsu" />

                <motion.p
                    animate={animation}
                    initial="hidden"
                    variants={footerDetAnime}
                    className="footer-txt">Our Goal is to create the largest Decentralized organization that bridges open gaps between physical businesses and web3</motion.p>

                <motion.div
                    animate={animation}
                    key={'footer-list-wrapper'}
                    initial="hidden"
                    variants={listAnime}
                    className="footer-nav-links-wrapper">
                    {links.map((link, ind) => (
                        <>

                            {(link === 'Gallery') ?
                                <Link
                                    variants={item}
                                    key={ind}
                                    to={`/gallery`} className="footer-link" >
                                    {link}
                                </Link>
                                :
                                <>
                                    {(link === 'Terms') ?

                                        <>
                                            <Link
                                                variants={item}
                                                key={ind}
                                                to={`/terms-and-conditions`} className="footer-link" >
                                                {link}
                                            </Link>
                                        </> : <>

                                            <motion.a
                                                variants={item}
                                                key={ind}
                                                href={`/#${link}`} className="footer-link" >
                                    {link}
                                </motion.a>
                                        </>
                                    }
                                </>
                            }
                        </>
                    ))}
                </motion.div>

                <div className="footer-social-wrapper">
                    <motion.div

                        animate={animation}
                        initial="hidden"
                        variants={listAnime}

                        className="footer-links-wrapper">

                        <motion.a
                            variants={item}
                            key={`discord-link`}
                            href={DISCORD} target="_blank" className="footer-link" rel="noreferrer">
                            <FaDiscord />
                        </motion.a>
                        <motion.a
                            variants={item}
                            key={`twitter-link`}
                            href={TWITTER} target="_blank" className="footer-link" rel="noreferrer">
                            <FaTwitter />
                        </motion.a>


                        <motion.a
                            variants={item}
                            key={`instagram-link`}
                            href={INSTAGRAM} target="_blank" className="footer-link" rel="noreferrer">
                            <FaInstagram />
                        </motion.a>
                        <motion.a
                            variants={item}
                            key={`opensea-link`}
                            href={OPENSEA} target="_blank" className="footer-link" rel="noreferrer">
                            <img src="/icons/opensea.png" alt="open sea" />
                        </motion.a>
                    </motion.div>

                </div>
            </div>

            <div className="copyright-wrapper">
                <small>© Copyright Zugaikotsu 2022 </small>
            </div>

        </motion.footer>
    );
}

export default Footer;
