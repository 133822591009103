import { useEffect } from "react";
import '../App.scss';

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";


const header = {
    visible: {
        opacity: 1,
        translateY: 0,
        transition: {
            duration: 1,
        },
    },
    hidden: {
        opacity: 0,
        translateY: -50,
    },
};



const image_animate = {
    visible: {
        opacity: 1,
        transition: {
            duration: 1,
            delay: .5,
        },
    },
    hidden: {
        opacity: 0,
    },
};

const Txt_animate = {
    visible: {
        opacity: 1,
        transition: {
            duration: 2,
            delay: 1,
            staggerChildren: .5,
        },
    },
    hidden: {
        opacity: 0,
        staggerDirection: -1
    },
};

const Txt_item = {
    hidden: {
        opacity: 0,
        translateY: -50,
    },
    visible: {
        opacity: 1,
        translateY: 0,
    }
}


function About() {
    const animation = useAnimation();
    const { ref, inView } = useInView({ threshold: 0.1 });

    useEffect(() => {
        if (inView) {
            animation.start("visible");
        } else {
            animation.start("hidden");
        }
    }, [animation, inView]);

    return (
        <>
            <motion.div
                ref={ref}
                id="About" className="about-section">
                <motion.div
                    animate={animation}
                    initial="hidden"
                    variants={header}

                    className="page-header-wrapper lg-hidden mb-100">
                    <img src="/images/titles/about.svg" alt="About" />
                </motion.div>



                <div className="about-component-wrapper">
                    <div className="about-left-wrapper">

                        <motion.img
                            animate={animation}
                            initial="hidden"
                            variants={image_animate}
                            // className="about-img" src="/images/about.svg" alt="Zugaikotsu" />
                            className="about-img" src="/logo.jpg" alt="Zugaikotsu" />

                    </div>
                    <motion.div
                        animate={animation}
                        initial="hidden"
                        variants={Txt_animate}

                        className="about-right-wrapper">

                        <motion.div
                            key="about-header"
                            variants={Txt_item}
                            className="page-header-wrapper  sm-hidden mb-50">
                            <img src="/images/titles/about.svg" alt="About" />
                        </motion.div>

                        <motion.p
                            variants={Txt_item}
                            key="about-item-1"
                            className="mb-20">
                            We never saw what code could bring,
                            We never knew of NFTs,
                            The people sang "to bring them close, there needs to be community

                            <br /><br />
                            So together we come and bridge the gap from web3 and reality.<br />

                            We Are Zugaikotsu”
                            {/*
                            <br /><br />
                            Our Goal is to create the largest Decentralized organization that bridges open gaps between physical businesses and web3. */}

                        </motion.p>
                    </motion.div>
                </div>
            </motion.div>


        </>
    );
}

export default About;
