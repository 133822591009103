import { useEffect, useState } from "react";
import '../App.scss';
import { ethers } from "ethers";
import { useStore } from "../modules/store";

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";


import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import {
    ethereum,
    authenticate,
    mintNft
} from "../modules/wallet";

const responsive = {
    0: { items: 2 },
    760: { items: 2 },
    1140: { items: 1 },
    1500: { items: 1 },
    1600: { items: 1 },
};


const header = {
    visible: {
        opacity: 1,
        translateY: 0,
        transition: {
            duration: 1,
        },
    },
    hidden: {
        opacity: 0,
        translateY: 30,
    },
};
const sub_header = {
    visible: {
        opacity: 1,
        translateY: 0,
        transition: {
            duration: 1,
            delay: .3,
        },
    },
    hidden: {
        opacity: 0,
        translateY: 30,
    },
};
const mint_options = {
    visible: {
        opacity: 1,
        translateY: 0,
        transition: {
            duration: 1,
            delay: .6,
        },
    },
    hidden: {
        opacity: 0,
        translateY: 30,
    },
};
const call_to_action_anime = {
    visible: {
        opacity: 1,
        translateY: 0,
        transition: {
            duration: 1,
            delay: 1,
        },
    },
    hidden: {
        opacity: 0,
        translateY: 30,
    },
};



function Home() {

    const [successMsg, setSuccessMsg] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [infoMsg, setInfoMsg] = useState(null);


    const animation = useAnimation();
    const { ref, inView } = useInView({ threshold: 0.1 });
    const [count, setCount] = useState(1);

    // const [total, setTotal] = useState(null);

    const {
        accounts, authenticating,
        setChainId,
        setAuthenticating,
        setAccounts, setSigner, SET_OWNER,
        provider,
        minting, setMinting,
        OWNER,

        PAUSED, SET_PAUSED,
        // SET_ONLY_WHITELISTED, SET_IS_WHITELISTED,
        PUBLIC_SALE_COST,
        // PRE_SALE_COST,

        SET_TOTAL_SUPPLY,
        // PRE_SALE_TOTAL, 
        PUBLIC_SALE_TOTAL,
        TOTAL_SUPPLY,
        //  ONLY_WHITELISTED, IS_WHITELISTED,
    } = useStore();

    const [isConnected, setIsConnected
    ] = useState(Boolean(accounts[0]));


    useEffect(() => {
        if (errorMsg) {
            let _msgTimeout = setTimeout(() => {
                setErrorMsg(null);
            }, 10000);
            return (() => {
                if (_msgTimeout) {
                    clearTimeout(_msgTimeout)
                }
            });
        } else if (successMsg) {
            let _msgTimeout = setTimeout(() => {
                setSuccessMsg(null);
            }, 10000);
            return (() => {
                if (_msgTimeout) {
                    clearTimeout(_msgTimeout)
                }
            });
        } else if (infoMsg) {
            let _msgTimeout = setTimeout(() => {
                setInfoMsg(null);
            }
                , 10000);
            return (() => {
                if (_msgTimeout) {
                    clearTimeout(_msgTimeout)
                }
            });
        }

    }, [errorMsg, successMsg, infoMsg]);


    // useEffect(() => {
    //     if (isConnected && (count && count > 0)) {
    //         const cost = (ONLY_WHITELISTED) ? PRE_SALE_COST : PUBLIC_SALE_COST;
    //         const _total = (cost * count).toFixed(2);
    //         setTotal(_total);
    //     } else {
    //         const _total = (PUBLIC_SALE_COST * count).toFixed(2);
    //         setTotal(_total);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [count]);

    useEffect(() => {
        if (inView) {
            animation.start("visible");
        } else {
            animation.start("hidden");
        }
    }, [animation, inView]);



    const incrementCounter = () => {
        if (count < 5) {
            setCount(count + 1);
        }
    }
    const decrementCounter = () => {
        if (count >= 2) {
            setCount(count - 1);
        }
    }
    const connectWallet = async () => {
        setAuthenticating(true);
        try {

            const {
                accounts,
                chainId,
                signer,
                paused,
                // onlyWhitelisted,
                // isWhitelisted,
                totalSupply,
                owner
            } = await authenticate();



            setAccounts(accounts);
            setChainId(chainId);
            setSigner(signer);
            SET_PAUSED(paused);
            // SET_ONLY_WHITELISTED(onlyWhitelisted);
            // SET_IS_WHITELISTED(isWhitelisted);
            SET_TOTAL_SUPPLY(totalSupply);
            SET_OWNER(owner);

            setAuthenticating(false);
            notify("Successfully logged in.", "SUCCESS")
        } catch (e) {

            if (e.code === -32000) {
                notify('Error: Please try authenticating again.', "ERROR");
            } else if (e.code === -32002) {
                notify('Authenticating: Please check for Metamask popup.', "INFO");
            } else {
                // notify(e.message, "ERROR");
                notify(`Please wait ...`, "ERROR");
            }
            setAuthenticating(false);

        }
    }


    useEffect(() => {
        if (localStorage.root) {
            localStorage.removeItem('root');
        }
    }, []);

    useEffect(() => {
        const check = Boolean(accounts[0]);
        setIsConnected(check);

        if (ethereum && !check) {
            notify("Please connect your wallet to mint an NFT.", "INFO");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts]);

    const notify = (msg, type) => {
        setErrorMsg(null);
        setSuccessMsg(null);
        setInfoMsg(null);
        switch (type) {
            case "INFO":
                setInfoMsg(msg);
                break;
            case "SUCCESS":
                setSuccessMsg(msg);
                break;
            case "ERROR":
                setErrorMsg(msg);
                break;
            default:
                setInfoMsg(msg);
                break;
        }
    };
    const clearMsgs = () => {
        setErrorMsg(null);
        setSuccessMsg(null);
        setInfoMsg(null);
    }


    const handleConnect = async ({ chainId }) => {
        console.log("Handling 'connect' event", chainId);
    };
    const handleAccountsChanged = (_accounts) => {
        if (accounts.length) {
            setAccounts(_accounts);
        }
    };
    const handleNetworkChanged = async (networkId) => {

        if (provider) {
            const { chainId } = await provider.getNetwork();
            setChainId(chainId);
            if (Number(process.env.REACT_APP_NETWORK_ID) === chainId) {
                notify(`Please change the newtork to ${process.env.REACT_APP_NETWORK_NAME}`, "ERROR");
            }
        } else {
            clearMsgs();
            connectWallet();
        }
    };

    if (ethereum) {
        ethereum.on("connect", handleConnect);
        ethereum.on("accountsChanged", handleAccountsChanged);
        ethereum.on("chainChanged", handleNetworkChanged);
    }


    const mint = async (quantity) => {
        setMinting(true);

        try {

            let _value = 0;
            let _curr_acc = ethers.utils.getAddress(`${accounts[0]}`);
            let _owners_acc = ethers.utils.getAddress(`${OWNER}`);

            if (_curr_acc !== _owners_acc) {
                if (PAUSED) return notify("Contract has been Paused.", "ERROR");
                // if (ONLY_WHITELISTED && !IS_WHITELISTED) return notify("You are not whitelisted", "ERROR");
                // _value = (ONLY_WHITELISTED) ? PRE_SALE_COST : PUBLIC_SALE_COST;
                _value = PUBLIC_SALE_COST;
            }

            const {
                // tx_hash,
                paused,
                // onlyWhitelisted,
                // isWhitelisted,
                totalSupply,
            } = await mintNft(quantity, _value);
            SET_PAUSED(paused);
            // SET_ONLY_WHITELISTED(onlyWhitelisted);
            // SET_IS_WHITELISTED(isWhitelisted);
            SET_TOTAL_SUPPLY(totalSupply);

            setMinting(false);
            notify("Successfully Minted.", "SUCCESS");

        } catch (e) {
            if (e.code !== -32002) {
                notify('Authenticating: Please check for Metamask popup.', "INFO");
                await connectWallet();
                setMinting(false);
                return mintNft();
            } else {
                // notify(`${e.message}`, "ERROR");
                notify(`Please wait ...`, "ERROR");
            }
            setMinting(false);
        }
    }


    let carousel_1 = [];
    let carousel_2 = [];
    const handleDragStart = (e) => e.preventDefault();
    for (let i = 1; i < 8; i++) {
        // console.log(i);


        if (i <= 4) {
            carousel_1.push(

                <picture className="landing-dogs-wrapper">
                    <source srcSet={`/images/samples/${i}.webp`} type="image/webp" />
                    <source srcSet={`/images/samples/${i}.png`} type="image/jpg" />

                    <img src={`/images/samples/${i}.webp`} className="carousel-nft-img" key={i} onDragStart={handleDragStart} role="presentation" alt={`Lonely_dog_${i}`} />
                </picture>

            );


        } else {
            carousel_2.push(
                <picture className="landing-dogs-wrapper">
                    <source srcSet={`/images/samples/${i}.webp`} type="image/webp" />
                    <source srcSet={`/images/samples/${i}.jpg`} type="image/jpg" />

                    <img src={`/images/samples/${i}.webp`} className="carousel-nft-img" key={i} onDragStart={handleDragStart} role="presentation" alt={`Lonely_dog_${i}`} />
                </picture>
            );
        }
    }


    return (
        <>
            <motion.div
                ref={ref}
                id="Home" className="home-section">

                <div className="mint-section-wrapper">
                    <motion.img
                        animate={animation}
                        initial="hidden"
                        variants={header}
                        src="/logo_letter.svg" className="landing-logo mb-30" alt="Zugaikotsu" />

                    <motion.p
                        animate={animation}
                        initial="hidden"
                        variants={sub_header}
                        className="landing-txt mb-20">ETH + Metamask </motion.p>

                    <motion.div
                        animate={animation}
                        initial="hidden"
                        variants={mint_options}
                        className="mint-opt-wrapper mb-10">
                        <div className="mint-opt-header-wrapper">
                            <div className="mint-opt-tab-item active">
                                <img src="/icons/red-eth.svg" className="network-icon" alt="ETH" />
                                ETH (Ethereum)</div>
                            {/* <div className="mint-opt-tab-item">
                                <img src="/icons/green-eth.svg" className="network-icon" alt="WETH" />

                                WETH</div>
                            <div className="mint-opt-tab-item">
                                <img src="/icons/matic.svg" className="network-icon" alt="matic" />

                                MATIC</div> */}
                        </div>
                        <div className="mint-opt-body-wrapper">

                            <div className="left-wrapper">

                                <button className="accent-btn min-adjust-btn"
                                    onClick={incrementCounter}
                                    disabled={minting}
                                >+</button>
                                <b>{count}</b>
                                <button className="accent-btn min-adjust-btn"
                                    onClick={decrementCounter}
                                    disabled={minting}
                                >-</button>
                            </div>
                            <div className="right-wrapper">
                                {/* <h3>{TOTAL_SUPPLY || 0}/{(ONLY_WHITELISTED) ? PRE_SALE_TOTAL : PUBLIC_SALE_TOTAL} <small>({(ONLY_WHITELISTED) ? 'Pre-Sale' : 'Public Sale'})</small></h3> */}
                                <h3>{TOTAL_SUPPLY || 0}/{PUBLIC_SALE_TOTAL} <small>Public Sale</small></h3>
                                {/* <h1>{total && `Total: ${total} ETH`}</h1> */}
                                <h1>Free Mint</h1>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div
                        animate={animation}
                        initial="hidden"
                        variants={mint_options}
                        className="messages-wrapper mb-20">
                        {(infoMsg) && <p className="info-txt mb-5">{infoMsg}</p>}
                        {(successMsg) && <p className="success-txt mb-5">{successMsg}</p>}
                        {(errorMsg) && <p className="error-txt mb-5">{errorMsg}</p>}
                    </motion.div>

                    {(window.ethereum) ?

                        <>
                            {(isConnected) ?
                                <motion.button

                                    animate={animation}
                                    initial="hidden"
                                    variants={call_to_action_anime}

                                    className="primary-btn call-to-action"
                                    disabled={minting}
                                    onClick={() => mint(count)}
                                >
                                    {(minting) ? "Minting..." : `Mint Now`}
                                </motion.button>

                                :
                                <motion.button
                                    animate={animation}
                                    initial="hidden"
                                    variants={call_to_action_anime}
                                    className="primary-btn call-to-action"
                                    onClick={() => connectWallet()}
                                    disabled={authenticating}

                                >
                                    {authenticating ? "Connecting..." : "Connect Wallet"}
                                </motion.button>


                            }
                        </>
                        :
                        <>
                            <p className="info-txt mb-5">Please install Metamask to Mint an NFT.</p>
                            <a href="https://metamask.io/download/" target={'_blank'} rel="noreferrer">
                                <button className="primary-btn call-to-action"
                                >
                                    Install Metamask                                </button>                    </a>
                        </>

                    }
                </div>

                <div className="home-carousel-wrapper">
                    <AliceCarousel

                        mouseTracking items={carousel_1}
                        animationEasingFunction={'linear'}
                        autoPlayControls={false}
                        disableButtonsControls={true}
                        disableDotsControls={true}
                        autoWidth
                        autoPlay={true}
                        autoPlayInterval="2000"
                        animationType={'slide'}
                        infinite={true}
                        responsive={responsive}
                        controlsStrategy="alternate"
                    />

                    <AliceCarousel mouseTracking items={carousel_2}
                        animationEasingFunction={'linear'}

                        autoPlayControls={false}
                        disableButtonsControls={true}
                        disableDotsControls={true}
                        autoPlayDirection="rtl"
                        autoWidth
                        autoPlay={true}
                        autoPlayInterval="2000"
                        animationType={'slide'}
                        infinite={true}
                        responsive={responsive}
                        controlsStrategy="alternate"
                    />
                </div>
            </motion.div>
        </>
    );
}

export default Home;
