

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const notify = (msg, type) => {
    const defConfig = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    switch (type) {
        case "INFO":
            toast.info(`${msg}`, defConfig)
            break;
        case "SUCCESS":
            toast.success(`${msg}`, { ...defConfig })
            break;
        case "ERROR":
            toast.error(`${msg}`, defConfig)
            break;
        default:
            toast(`${msg}`, defConfig)
            break;
    }

};
