import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Jazzicon } from '@ukstv/jazzicon-react';
import { Link } from "react-router-dom";

import '../App.scss';
import { FaTwitter, FaDiscord, FaInstagram, FaBars, FaRegTimesCircle } from "react-icons/fa";

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import { useStore } from "../modules/store";


const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input;

const logo_animate = {
    hidden: {
        opacity: 0,
        translateY: -50,
    },
    visible: {
        opacity: 1,
        translateY: 0,
        transition: {
            duration: 1,

        },
    }
};


const nav_list_anime = {
    hidden: {
        delay: 2,
        opacity: 0,
        staggerDirection: -1,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: .5,
            delayChildren: .5,
            staggerChildren: .3,
        },
    },
};
const nav_right_anime = {
    hidden: {
        delay: 2,
        opacity: 0,
        staggerDirection: -1,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 2,
            delayChildren: .5,
            staggerChildren: .3,
        },
    },
};

const item_anime = {
    hidden: {
        opacity: 0,
        translateY: -50,
    },
    visible: {
        opacity: 1,
        translateY: 0,
    }
}




const Navbar = ({ propConnectWallet }) => {
    const { accounts, authenticating,
        DISCORD, TWITTER, INSTAGRAM, OPENSEA
    } = useStore();

    const isConnected = Boolean(accounts[0]);

    const links = ["Home", "Gallery", "About", "Roadmap", "Team"];
    const [showMenu, setShowMenu] = useState(false);

    const animation = useAnimation();
    const { ref, inView } = useInView({ threshold: 0.1 });

    useEffect(() => {
        if (inView) {
            animation.start("visible");
        } else {
            animation.start("hidden");
        }
    }, [animation, inView]);

    return (
        <>
            {(!showMenu) ?
                <motion.div
                    ref={ref}
                    className="navbar">
                    <a href="/#Home" className="logo-wrapper" rel="noreferrer">
                        <picture>
                            <source srcSet={`/logo.webp`} type="image/webp" />
                            <source srcSet={`/logo.png`} type="image/png" />

                            <motion.img
                                animate={animation}
                                initial="hidden"
                                variants={logo_animate}

                                className="logo" src="/logo.webp" alt="Zugaikotsu" />
                        </picture>

                    </a>

                    <motion.div
                        animate={animation}
                        initial="hidden"
                        variants={nav_list_anime}

                        className="nav-links-wrapper sm-hidden">


                        {links.map(link => (

                            <motion.div
                                key={`nav-link-${link}`}
                                variants={item_anime}
                                className="nav-link-item">

                                {(link === 'Gallery') ?
                                    <Link to={'/gallery'} className="nav-link" >
                                        {link}
                                    </Link>
                                    : <a href={`/#${link}`} className="nav-link" >
                                        {link}
                                    </a>
                                }


                            </motion.div>
                        ))}

                    </motion.div>

                    <motion.div
                        animate={animation}
                        initial="hidden"
                        variants={nav_right_anime}
                        className="nav-connect-wrapper">

                        <motion.a
                            key="nav-discord-link"
                            variants={item_anime}
                            href={DISCORD} target="_blank" className="nav-link sm-hidden" rel="noreferrer">
                            <FaDiscord />
                        </motion.a>
                        <motion.a
                            key="nav-twitter-link"
                            variants={item_anime}
                            href={TWITTER} target="_blank" className="nav-link sm-hidden" rel="noreferrer">
                            <FaTwitter />
                        </motion.a>
                        <motion.a

                            key="nav-insta-link"
                            variants={item_anime}
                            href={INSTAGRAM} target="_blank" className="nav-link sm-hidden" rel="noreferrer">
                            <FaInstagram />
                        </motion.a>
                        <motion.a
                            key="nav-opensea-link"
                            variants={item_anime}
                            href={OPENSEA} target="_blank" className="nav-link sm-hidden" rel="noreferrer">
                            <img src="/icons/opensea.png" alt="open sea" />
                        </motion.a>

                        {(window.ethereum) ?
                            <>
                                {(!isConnected) ?

                                    <motion.button
                                        key="nav-connect-btn"
                                        variants={item_anime}
                                        onClick={() => propConnectWallet()}
                                        className="primary-btn"
                                    >
                                        {authenticating ? "Connecting..." : "Connect Wallet"}
                                    </motion.button>
                                    :
                                    <>
                                        {(accounts && accounts[0]) &&
                                            <>
                                            <div className='account-display'>
                                                <div style={{ width: '35px', height: '35px', }}>
                                                    <Jazzicon address={accounts[0]} />
                                                </div>
                                                <div>
                                                    <span>{truncate(accounts[0], 10)}</span>

                                                </div>
                                            </div>
                                        </>

                                        }

                                    </>
                                }
                            </>
                            :

                            <a href="https://metamask.io/download/" target={'_blank'} className="outline-btn" rel="noreferrer">
                                Install Metamask
                            </a>
                        }

                        <div className="nav-menu-link lg-hidden">
                            <FaBars onClick={() => setShowMenu(true)} />
                        </div>



                    </motion.div>
                </motion.div>
                :
                <div className="mobile-nav">
                    <div className="mobile-header-nav">

                        <a
                            href="#Home" className="logo-wrapper" rel="noreferrer">
                            <img className="logo" src="/logo.png" alt="Zugaigatsu" />
                        </a>


                        <div className="mobile-nav-left">



                            {(!isConnected) ?

                                <button
                                    onClick={() => propConnectWallet()}
                                    className="primary-btn"
                                >
                                    {authenticating ? "Connecting..." : "Connect Wallet"}
                                </button>
                                :
                                <>
                                    {(accounts && accounts[0]) &&
                                        <button className="primary-btn"
                                        >{truncate(accounts[0], 10)}</button>
                                    }

                                </>
                            }


                            <div className="nav-menu-link lg-hidden">
                                <FaRegTimesCircle onClick={() => setShowMenu(false)} />
                            </div>
                        </div>
                    </div>

                    <div className="mobile-nav-links-wrapper">

                        {links.map((link, ind) => (
                            <>
                                {(link === 'Gallery') ?
                                    <div className="nav-link-item">
                                        <Link to={'/gallery'} className="nav-link" onClick={() => setShowMenu(false)} >
                                            {link}
                                        </Link>
                                    </div>
                                    :
                                    <div className="nav-link-item">
                                        <a href={`/#${link}`} className="nav-link" onClick={() => setShowMenu(false)} >
                                            {link}
                                        </a>
                                    </div>
                                }
                            </>

                        ))}
                    </div>
                    <div className="mobile-nav-social-links">
                        <a href={DISCORD} target="_blank" className="nav-link " rel="noreferrer">
                            <FaDiscord />
                        </a>
                        <a href={TWITTER} target="_blank" className="nav-link" rel="noreferrer">
                            <FaTwitter />
                        </a>
                        <a href={INSTAGRAM} target="_blank" className="nav-link" rel="noreferrer">
                            <FaInstagram />
                        </a>
                        <a href={OPENSEA} target="_blank" className="nav-link" rel="noreferrer">
                            <img src="/icons/opensea.png" alt="open sea" />
                        </a>
                    </div>

                </div>

            }
            <ToastContainer />
        </>
    );
}

export default Navbar;
