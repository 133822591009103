import createStore from 'zustand';
import persist from '../utils/persist';

import { etherScanUrl } from "./wallet";

export const useStore = createStore(
    persist(
        {
            key: 'zugaikotsu-storage',
            denylist: ['minting', 'authenticating']
        },
        (set) => ({
            accounts: [],
            setAccounts: (val) => set(state => ({ accounts: val || [] })),
            setChainId: (val) => set(state => ({ network_id: val || Number(process.env.REACT_APP_NETWORK_ID) })),

            signer: [],
            setSigner: (val) => set(state => ({ signer: val || [] })),

            authenticating: false,
            setAuthenticating: (val) => set(state => ({ authenticating: val })),

            minting: false,
            setMinting: (val) => set(state => ({ minting: val })),

            LAUNCH_DATE: Date.parse("2022-07-29T10:00:00.000"),

            CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS,
            NETWORK_ID: Number(process.env.REACT_APP_NETWORK_ID),
            NETWORK_NAME: process.env.REACT_APP_NETWORK_NAME,

            PUBLIC_SALE_COST: 0,
            // PRE_SALE_COST: 0,

            PUBLIC_SALE_TOTAL: 10000,
            // PRE_SALE_TOTAL: 1000,

            PAUSED: 0,
            SET_PAUSED: (val) => set(state => ({ TOTAL_SUPPLY: val })),

            TOTAL_SUPPLY: 0,
            SET_TOTAL_SUPPLY: (val) => set(state => ({ TOTAL_SUPPLY: val })),

            // ONLY_WHITELISTED: false,
            // SET_ONLY_WHITELISTED: (val) => set(state => ({ ONLY_WHITELISTED: val })),

            // IS_WHITELISTED: false,
            // SET_IS_WHITELISTED: (val) => set(state => ({ IS_WHITELISTED: val })),

            OWNER: undefined,
            SET_OWNER: (val) => set(state => ({ OWNER: val })),

            DISCORD: "https://discord.com/invite/nj3RRyMz6Y",
            TWITTER: "https://twitter.com/ZugaiKotsuNFT",
            INSTAGRAM: "https://www.instagram.com/zugaikotsunft/",
            OPENSEA: "https://opensea.io/collection/zugaikotsu-v2",
            ETHERSCAN: `${etherScanUrl(process.env.REACT_APP_NETWORK_ID, process.env.REACT_APP_CONTRACT_ADDRESS)}`,
        })
    )
);

