import '../App.scss';
function Loader() {
    return (
        <div className="loader-wrapper">
            <div className="loader-spinner-wrapper">
                <div className="sk-chase">
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot" ></div >
                    <div className="sk-chase-dot" ></div >
                    <div className="sk-chase-dot" ></div >
                    <div className="sk-chase-dot" ></div >
                </div>
            </div>

            <p className="loader-txt">Please wait...</p>
        </div>
    );
}

export default Loader;




