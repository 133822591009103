import './App.scss';
import { useEffect, useState } from 'react';
import { PersistGate } from 'zustand-persist'

import Navbar from './components/Navbar';
import Home from './components/Home';
import Gallery from './components/Gallery';
import About from './components/About';
import Roadmap from './components/Roadmap';
import Terms from './components/Terms';
import Team from './components/Team';
import Faq from './components/Faq';
import Footer from './components/Footer';

import { notify } from "./utils/feedback";
import { useStore } from './modules/store';
import * as _ from "lodash";

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import {
  ethereum,
  authenticate,
  isMetamaskInstalled,
  mintNft,
} from "./modules/wallet";

function App() {
  const [txMintHash, setMinthash] = useState(null);
  const {
    accounts, setAccounts,
    setSigner,
    setAuthenticating,
    setMinting,

    PAUSED,
    SET_PAUSED,
    SET_TOTAL_SUPPLY,
    // ONLY_WHITELISTED,
    // SET_ONLY_WHITELISTED,
    // IS_WHITELISTED,
    // SET_IS_WHITELISTED,
    OWNER,
    SET_OWNER,

    NETWORK_ID,
    NETWORK_NAME

  } = useStore()

  useEffect(() => {
    if (!isMetamaskInstalled) {
      notify("Please install Metamask extension.", "ERROR")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectWallet = async () => {
    setAuthenticating(true);
    try {

      const {
        accounts,
        signer,
        paused,
        // onlyWhitelisted,
        // isWhitelisted,
        totalSupply,
        owner
      } = await authenticate();
      setAccounts(accounts);
      setSigner(signer);
      SET_PAUSED(paused);
      // SET_ONLY_WHITELISTED(onlyWhitelisted);
      // SET_IS_WHITELISTED(isWhitelisted);
      SET_TOTAL_SUPPLY(totalSupply);
      SET_OWNER(owner);

      setAuthenticating(false);
      notify("Successfully logged in.", "SUCCESS")
    } catch (e) {
      setAuthenticating(false)

      if (e.code === -32000) {
        notify('Error: Please try authenticating again.', "ERROR");
      } else if (e.code === -32002) {
        // notify('Please wait: Authenticating.', "INFO");
        notify('Authenticating: Please check for Metamask popup.', "INFO");
      } else {
        // notify(e.message, "ERROR");
        notify(`Please wait ...`, "ERROR");
      };

    }
  }

  const handleConnect = async ({ chainId }) => {
    console.log("Handling 'connect' event", chainId);
  };
  const handleAccountsChanged = (_accounts) => {
    if (accounts.length) {
      setAccounts(_accounts);
    }
  };
  const handleNetworkChanged = async (networkId) => {
    if (Number(networkId) !== NETWORK_ID) {
      // notify(`Change network to ${NETWORK_NAME}.`, "ERROR");
      _.debounce(notify(`Change network to ${NETWORK_NAME}.`, "ERROR"), 7000);
    }
  };

  if (ethereum) {
    ethereum.on("connect", handleConnect);
    ethereum.on("accountsChanged", handleAccountsChanged);
    ethereum.on("chainChanged", handleNetworkChanged);
  }

  const mint = async (quantity) => {
    setMinting(true);

    try {
      const curr_acc = String(accounts[0]).toLocaleLowerCase();
      const owner_acc = String(OWNER).toLocaleLowerCase();

      if (curr_acc === owner_acc) {
        const {
          accounts,
          tx_hash,
          signer,
          paused,
          // onlyWhitelisted,
          // isWhitelisted,
          totalSupply,
        } = await mintNft(quantity);

        setAccounts(accounts);
        setSigner(signer);
        SET_PAUSED(paused);
        // SET_ONLY_WHITELISTED(onlyWhitelisted);
        // SET_IS_WHITELISTED(isWhitelisted);
        SET_TOTAL_SUPPLY(totalSupply);

        setMinthash(tx_hash);
        setMinting(false);
        notify("Successfully Minted.", "SUCCESS");


      } else {
        if (PAUSED) return notify("Contract has been Paused.", "ERROR");
        // if (ONLY_WHITELISTED && !IS_WHITELISTED) return notify("You are not whitelisted", "ERROR");
        const {
          accounts,
          tx_hash,
          signer,
          paused,
          // onlyWhitelisted,
          // isWhitelisted,
          totalSupply,
        } = await mintNft(quantity);
        setAccounts(accounts);
        setSigner(signer);
        SET_PAUSED(paused);
        // SET_ONLY_WHITELISTED(onlyWhitelisted);
        // SET_IS_WHITELISTED(isWhitelisted);
        SET_TOTAL_SUPPLY(totalSupply);


        setMinthash(tx_hash);

        setMinting(false);
        notify("Successfully Minted.", "SUCCESS");
      }
    } catch (e) {
      // notify(`${e.message}`, "ERROR");
      notify(`Please wait ...`, "ERROR");
      setMinting(false);
    }
  }



  return (
    <PersistGate>

    <div className="scroll-area">
      <BrowserRouter className="App">

          <Navbar
            propConnectWallet={() => connectWallet()}
          />

          <Routes>

            <Route path='/' element={
              <>
                <Home />

                <About />
                <Roadmap />
                <Team />
                <Faq />
              </>
            } exact />

            <Route path='/gallery' element={
              <Gallery
                txMintHash={txMintHash}
                propConnectWallet={() => connectWallet()}
                propMint={(quantity) => mint(quantity)}
              />
            } exact />

            <Route path='/terms-and-conditions' element={
              <Terms
              />
            } exact />

          </Routes>
          <Footer />

          <video playsInline autoPlay muted loop className="video-bg">
            <source src="/videos/bg_video.webm" type="video/webm" />
            <source src="/videos/bg_video.mp4" type="video/mp4" />
          </video>

      </BrowserRouter>
    </div>
    </PersistGate >


  );
}

export default App;
