import { ethers } from "ethers";

// Init Etherium
export const { ethereum } = window;
export const isMetamaskInstalled = ethereum && ethereum.isMetaMask;

export const provider = (ethereum) ? new ethers.providers.Web3Provider(ethereum) : null;

const ERC721A_ABI = [
    "function mint(uint256 quantity) payable",
    "function cost() view returns (uint256)",
    "function paused() view returns (bool)",
    // "function onlyWhitelisted() view returns (bool)",
    // "function isWhitelisted(address _user) view returns (bool)",
    "function totalSupply() view returns (uint256)",
    "function owner() view returns (address)",
]

export const authenticate = async () => {
    if (ethereum && isMetamaskInstalled) {

        const accounts = await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        const { chainId } = await provider.getNetwork();

        if (Number(process.env.REACT_APP_NETWORK_ID) === chainId) {
            const _contract = new ethers.Contract(`${process.env.REACT_APP_CONTRACT_ADDRESS}`, ERC721A_ABI, signer);

            const paused = await _contract.paused();
            // const onlyWhitelisted = await _contract.onlyWhitelisted();
            // const isWhitelisted = await _contract.isWhitelisted(accounts[0]);
            const fetchTotalSupply = await _contract.totalSupply();
            const owner = await _contract.owner();

            const _totalSupply = ethers.utils.formatUnits(fetchTotalSupply, 0);

            return {
                accounts,
                signer,
                chainId,
                paused,
                // onlyWhitelisted,
                // isWhitelisted,
                totalSupply: _totalSupply,
                owner,
            }
        } else {
            throw Error(`Please change the newtork to ${process.env.REACT_APP_NETWORK_NAME}`)
        }


    } else {
        throw Error("Please Install Metamask.");
    }
};

export const disconnect = async () => {
    try {
        localStorage.removeItem("account");
    } catch (e) {
        // throw Error(e.message);
        throw Error("Error while disconnecting.");
    }
}

export const mintNft = async (quantity, value) => {


    if (ethereum && isMetamaskInstalled) {

    try {

        const _quantity = ethers.utils.formatUnits(quantity, 0);
        const accounts = await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        const _contract = new ethers.Contract(`${process.env.REACT_APP_CONTRACT_ADDRESS}`, ERC721A_ABI, signer);

        // const _total = ethers.utils.parseEther(`${value}`).mul(_quantity);
        const _total = ethers.utils.parseEther("0.0");
        // const _total = ethers.utils.parseEther(0).mul(_quantity);

        // const _total = ethers.utils.parseEther(1).mul(_quantity);

        const tx = await _contract.mint(_quantity, {
            gasLimit: 100000,
            gasPrice: 20e9,
            value: _total,
        });

        const tx_hash = await tx.wait();

        const fetchCost = await _contract.cost();
        const paused = await _contract.paused();
        // const onlyWhitelisted = await _contract.onlyWhitelisted();
        // const isWhitelisted = await _contract.isWhitelisted(accounts[0]);
        const fetchTotalSupply = await _contract.totalSupply();
        const owner = await _contract.owner();

        const _cost = ethers.utils.formatEther(fetchCost);
        const _totalSupply = ethers.utils.formatUnits(fetchTotalSupply, 0);

        return {
            accounts,
            tx_hash,
            signer,
            cost: _cost,
            paused,
            // onlyWhitelisted,
            // isWhitelisted,
            totalSupply: _totalSupply,
            owner,
        }

    } catch (e) {
        // throw Error(e.message);
        throw Error("Error: while minting.");
        }
    } else {
        throw Error("Please Install Metamask.");
    }
}

export const etherScanUrl = (network, account) => {
    let etherscanUrl;
    if (network === 3) {
        etherscanUrl = 'https://ropsten.etherscan.io';
    } else if (network === 4) {
        etherscanUrl = 'https://rinkeby.etherscan.io';
    } else if (network === 42) {
        etherscanUrl = 'https://kovan.etherscan.io';
    } else if (network === 5) {
        etherscanUrl = 'https://goerli.etherscan.io';
    } else {
        etherscanUrl = 'https://etherscan.io';
    }

    return `${etherscanUrl}/address/${account}`;
}