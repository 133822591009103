import { useState, useEffect } from "react";
import '../App.scss';

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";


import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const header = {
  visible: {
    opacity: 1,
    translateY: 0,
    transition: {
      duration: 1,
    },
  },
  hidden: {
    opacity: 0,
    translateY: -50,
    transition: {
      duration: 1,
      delay: 2,
    },
  },
};

const item = {
  hidden: {
    opacity: 0,
    translateY: 100,
  },
  visible: {
    opacity: 1,
    translateY: 0,
  }
}


const responsive = {
  0: { items: 1 },
  760: { items: 2 },
  1140: { items: 2 },
  1500: { items: 3 },
  1600: { items: 4 },
};

function Team() {
  const animation = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    } else {
      animation.start("hidden");
    }
  }, [animation, inView]);

  const [team] = useState([
    {
      name: "Sedge",
      title: "Founder",
      description: "Co-Artist, Destro-Warlock",
      img: "Sedge",
      link: "",
      class: "color-pink"
    },
    {
      name: "Daffy",
      title: "Moderator",
      description: "Life of the Party, Thinks they’re good at Valorant",
      img: "Daffy",
      link: "",
      class: "color-pixel"
    },
    {
      name: "Viz",
      title: "Moderator",
      description: "Coder, still hasn’t beat Elden Ring",
      img: "Viz",
      link: "",
      class: "color-dreamy"
    },

    {
      name: "JM",
      title: "Main Artist",
      description: "Likes to take bubble baths.",
      img: "JM",
      link: "",
      class: "color-glitch"
    },
    {
      name: "Geoff",
      title: "Coder",
      description: "Bringing NFTs to the world.",
      img: "Geoff",
      link: "",
      class: "color-diamond"
    }

  ]);


  let team_items = [];
  const handleDragStart = (e) => e.preventDefault();
  for (let i = 0; i < team.length; i++) {
    const char = team[i]
    team_items.push(
      <motion.div

        className="team-item" key={i}>

        <motion.img
          variants={item}
          key={i}
          onDragStart={handleDragStart} role="presentation"
          className="team-img mb-20" src={`/images/team/${char.img}.png`} alt={char.name} />



        <motion.h3
          variants={item}
          className={`${char.class} team-title`}>{char.name}</motion.h3>
        <motion.p
          variants={item}
          className="team-sub-title mb-10">{char.title}</motion.p>
        <motion.p
          variants={item}
          className="def-txt">{char.description}</motion.p>
      </motion.div>
    );

  }




  return (
    <motion.div
      ref={ref}
      id="Team" className="team-section">

      <motion.img

        animate={animation}
        initial="hidden"
        variants={header}
        className="page-header-img" src="/images/titles/meet_the_team.svg" alt="Rarity" />
      <div className="page-section-wrapper">

        <div className="team-carousel-wrapper">
          <AliceCarousel mouseTracking items={team_items}
            autoWidth
            disableButtonsControls={false}

            autoPlay={true}
            autoPlayInterval="2500"
            animationType={'fadeout'}
            infinite={true}
            responsive={responsive}
            controlsStrategy="alternate"
          />
        </div>
      </div>
    </motion.div>
  );
}

export default Team;
