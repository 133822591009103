import { useState, useEffect } from "react";
import '../App.scss';

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";


const header = {
    visible: {
        opacity: 1,
        translateY: 0,
        transition: {
            duration: 1,
        },
    },
    hidden: {
        opacity: 0,
        translateY: -50,
    },
};


const listAnime = {
    visible: {
        opacity: 1,
        transition: {
            duration: 1,
            delay: 1,
            staggerChildren: .5,
        },
    },
    hidden: {
        opacity: 0,
        staggerDirection: -1
    },
};

const item = {
    hidden: {
        opacity: 0,
        translateY: 100,
    },
    visible: {
        opacity: 1,
        translateY: 0,
    }
}


function Faq() {
    const animation = useAnimation();
    const { ref, inView } = useInView({ threshold: 0.1 });

    useEffect(() => {
        if (inView) {
            animation.start("visible");
        } else {
            animation.start("hidden");
        }
    }, [animation, inView]);

    const [faqs, setFaqs] = useState([
        {
            title: "What is an NFT?",
            description: "Non-Fungible Tokens are unique, easily verifiable digital assets that can represent items such as GIFs, images, videos, music albums, and more. Anything that exists online can be purchased as an NFT, theoretically. ",
            active: true,
        },
        {
            title: "What is a Web3?",
            description: "Web3 is the “new” internet. We are currently operating in the web2 world where one must make a profile on a specific website through their own servers and platforms. By doing so, you are allowing companies to collect your personal data and possibly sell it to third parties. Web3 on the other hand allows the user to create an online profile on their own “internet wallet.”",
            active: false,
        },
        {
            title: "What is an internet wallet or metamask?",
            description: "An Internet wallet or Metamask wallet is a software cryptocurrency walled used to interact with a specified blockchain. There are other wallets that can be used such as Coinbase wallet, Trust Wallet, etc. However, the majority of Etherium blockchain users tend to choose Metamask.",
            active: false,
        },
        {
            title: "What is a DAO?",
            description: "In short, a DAO (Decentralized Autonomous Organization) is a community-led entity with no central authority. Which means that the community leads the project and votes on what the organization should do next. So far, we’ve seen projects that are a mix of Centralized authority and DAO where some decisions are given to the community while the main decisions are made by the founders. The goal with Zugaikotsu is to eventually hand over the project to the community and allow them to appoint leadership and dictate where the project goes next. A true test of full decentralization.",
            active: false,
        },
        // {
        //     title: "Why would I want to own an NFT? Why should I purchase a Zugaikotsu NFT?",
        //     description: "Most people purchase NFTs for their perceived or future value. Projects tend to add value to their NFT by providing exclusive content or special access to virtual and IRL events. Zugaikotsu is providing value a little differently; we are focused on increasing income value by creating a real-world connection to web3 technologies.",
        //     active: false,
        // },

        {
            title: "How do you buy a Zugaikotsu NFT?",
            description: "The Zugaikotsu NFT may be Minted on Launch Day (TBD) or purchased on Opensea.io after launch.",
            active: false,
        },

    ]);

    const updateFaq = async (ind) => {
        const faq = faqs[ind];
        if (faq.active) {
            const newList = await faqs.map((item, index) => {
                if (index === ind) {
                    return { ...item, active: false }
                }
                return item;
            })
            setFaqs(newList);

        } else {
            const newList = await faqs.map((item, index) => {
                if (index === ind) {
                    return { ...item, active: true }
                }
                return item;
            })
            setFaqs(newList);
        }
    }

    return (
        <>
            <motion.div ref={ref} id="Faq" className="faq-section">

                <motion.div
                    animate={animation}
                    initial="hidden"
                    variants={header}

                    className="page-header-wrapper mb-50">
                    <img src="/images/titles/faq.svg" alt="Roadmap" />
                </motion.div>


                <motion.div
                    animate={animation}
                    initial="hidden"
                    variants={listAnime}
                    className="faq-list-wrapper">

                    {faqs.map((faq, ind) => (
                        <motion.div
                            className={(faq.active) ? 'faq-item active mb-20' : 'faq-item mb-20'}
                            variants={item}
                            key={ind}
                            onClick={() => updateFaq(ind)}
                        >
                            <div className="faq-header-wrapper">
                                <h3>{faq.title}</h3>
                                <div className="action-wrapper">
                                    {(faq.active) ?
                                        <>-</>
                                        : <>+ </>
                                    }

                                </div>
                            </div>
                            {(faq.active) &&
                                <div className="faq-details-wrapper">
                                    <p className="def-txt pb-10">{faq.description}</p>
                                </div>
                            }
                        </motion.div>
                    ))}
                </motion.div>

            </motion.div>


        </>
    );
}

export default Faq;
