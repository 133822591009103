import { useEffect, useState } from "react";
import '../App.scss';

import Modal from 'react-modal';

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";


const header_anime = {
    hidden: {
        delay: 2,
        opacity: 0,
        translateY: 50,
    },
    visible: {
        opacity: 1,
        translateY: 0,
        transition: {
            duration: 1,

        },
    }
};
// const sub_header_anime = {
//     hidden: {
//         delay: 2,
//         opacity: 0,
//         translateY: 50,
//     },
//     visible: {
//         opacity: 1,
//         translateY: 0,
//         transition: {
//             duration: 1,
//             delay: .5

//         },
//     }
// };


const list_anime = {
    hidden: {
        delay: 2,
        opacity: 0,
        staggerDirection: -1,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: .5,
            delayChildren: .5,
            staggerChildren: .3,
        },
    },
};

const item_anime = {
    hidden: {
        opacity: 0,
        translateX: 20,
    },
    visible: {
        opacity: 1,
        translateX: 0,
    }
}





const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000000bf',
        border: 'none'
    },
};

Modal.setAppElement('#root');


function Roadmap() {
    const animation = useAnimation();
    const { ref, inView } = useInView({ threshold: 0.1 });


    const [modalIsOpen, setIsOpen] = useState(false);
    const [activeRoadmap, setActiveRoadmap] = useState(false);


    useEffect(() => {
        if (inView) {
            animation.start("visible");
        } else {
            animation.start("hidden");
        }
    }, [animation, inView]);

    function openModal(data) {
        setActiveRoadmap(data)
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const roadmap = [
        {
            ind: 1,
            title: "Transparency",
            description: "Some funds will be used for our team to quit their jobs and focus on this project.",
            img: "/roadmap/1.svg"
        },
        {
            ind: 2,
            title: "Community",
            description: "Build Web3 Members only section of website in preparation of DAO.",
            img: "/roadmap/2.svg"
        },
        {
            ind: 3,
            title: "Build",
            description: "Brainstorm business gaps and help bridge them within our members only community",
            img: "/roadmap/3.svg"
        },
        {
            ind: 5,
            title: "Grow",
            description: "Develop DAO and governance token based on the gaps voted by team members and officially launch the company.",
            img: "/roadmap/5.svg"
        },

        {
            ind: 4,
            title: "Create",
            description: "Create a plan of action once the community votes on two major gaps with web3 potential",
            img: "/roadmap/4.svg"
        },
        {
            ind: 6,
            title: "Execute",
            description: "Develop solid tokenomics to act as reward system for member contribution and royalty payouts from participation in DAO.",
            img: "/roadmap/6.svg"
        },
        {
            ind: 7,
            title: "Develop",
            description: "Create a plan of action once the community votes on two major gaps with web3 potential.",
            img: "/roadmap/7.svg"
        },
    ]

    useEffect(() => {
        if (inView) {
            animation.start("visible");
        } else {
            animation.start("hidden");
        }
    }, [animation, inView]);



    return (
        <>
            <div ref={ref} id="Roadmap" className="roadmap-section">

                <div className="roadmap-header-wrapper mb-50">

                    <div className="left-wrapper">
                        <motion.img

                            animate={animation}
                            initial="hidden"
                            variants={header_anime}

                            src="/images/titles/roadmap.svg" className="roadmap-title" alt="Roadmap" />
                    </div>
                    <div className="right-wrapper">
                        {/* <motion.p
                            animate={animation}
                            initial="hidden"
                            variants={sub_header_anime}
                        >Our goal is to create the first IRL DAO created by you, governed by you. </motion.p> */}
                    </div>
                </div>

                <motion.div

                    animate={animation}
                    initial="hidden"
                    variants={list_anime}

                    className="roadmap-grid-wrapper">

                    <>

                        {roadmap.map(item => (

                            <motion.div

                                variants={item_anime}

                                className='roadmap-item'
                                key={item.ind}
                                onClick={() => openModal(item)}
                                style={{ backgroundImage: `url(${item.img})` }}>
                                <h1><b>{item.ind}</b> <br />{item.title}</h1>
                            </motion.div>
                        )
                        )}


                        <div className="comming-soon-overlay">

                            <h1 className="page-sub-title">Comming Soon</h1>

                        </div>
                    </>



                </motion.div>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <div className="modal-body-top" >
                        <div className="modal-header mb-30">
                            <div className="left-wrapper">
                                <h2 className="modal-title">{activeRoadmap.title}</h2>

                            </div>
                            <div className="right-wrapper">
                                <button className="close-btn" onClick={closeModal}>X</button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>{activeRoadmap.description}</p>
                        </div>
                        <div className="shadow">&nbsp;</div>
                        <div className="img-shadow" style={{ backgroundImage: `url(${activeRoadmap.img})` }}>&nbsp;</div>
                    </div>

                </Modal>

            </div>


        </>
    );
}

export default Roadmap;
