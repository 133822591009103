import { useEffect, useState } from "react";
import '../App.scss';
import { fetchGallery, fetchFilter } from '../services/api';
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import ReactPaginate from 'react-paginate';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import * as _ from 'lodash';

import Loader from "./Loader";
import { Link } from "react-router-dom";

const list_anime = {
    hidden: {
        delay: 0,
        opacity: 0,
        staggerDirection: -1,
    },
    visible: {
        delay: 0,
        opacity: 1,
        transition: {
            delayChildren: .5,
            staggerChildren: .5,
        },
    },
};



const item_anime = {
    hidden: {
        opacity: 0,
        translateY: -10,
    },
    visible: {
        opacity: 1,
        translateY: 0,
    }
}

const Gallery = ({
    txMintHash,
    propConnectWallet,
    propMint

}) => {
    const itemsPerPage = 10;


    const ready = false;

    const [filter, setFilter] = useState(null);
    const [activeSubFilter, setActiveSubFilter] = useState(null);
    const [parentQuery, setParentQuery] = useState(null);
    const [childQuery, setChildQuery] = useState(null);

    const IMAGE_CID = "QmRwvQdhfQBTViGDDDEzgdcPLhtsnUM477tU6Zck6MKDW9"
    // const IPFS_GATEWAY = "https://bafybeibvtqr4ji6wcblfu2wi4xx5ouhm6fi462blkrkproottsgc4bzoxi.ipfs.dweb.link/"
    // const IMAGE_CID = "https://ipfs.io/ipfs/QmRwvQdhfQBTViGDDDEzgdcPLhtsnUM477tU6Zck6MKDW9/9567.png"
    const animation = useAnimation();
    const { ref, inView } = useInView({ threshold: 0.1 });

    const [gallery, setGallery] = useState([]);

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);



    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (inView) {
            animation.start("visible");
        } else {
            animation.start("hidden");
        }
    }, [animation, inView]);


    useEffect(() => {
        if (!filter) {
            (async () => {
                const data = await fetchFilter();
                setFilter(data);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);


    useEffect(() => {
        if (parentQuery) {
            setActiveSubFilter(parentQuery.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentQuery]);


    useEffect(() => {
        if (itemOffset === 0) {
            initCurrentItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemOffset, itemsPerPage]);


    // Invoke when user click to request another page.
    const initCurrentItems = () => {
        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(gallery.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(gallery.length / itemsPerPage));
    };


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % gallery.length;
        // console.log(
        //     `User requested page number ${event.selected}, which is offset ${newOffset}`
        // );
        setItemOffset(newOffset);
    };

    useEffect(() => {

        if (!gallery.length) {

            (async () => {
                const res = await fetchGallery();
                setGallery(res);
                initCurrentItems();

            })();
        } else {
            if (itemOffset === 0) {
                initCurrentItems();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gallery]);


    const filterBy = async (query) => {
        setChildQuery(query);
        const filteredList = _.filter(gallery, (item) => {
            if (item.attributes[parentQuery.index].value === query) {
                return item;
            }
        });
        setCurrentItems(filteredList)
    }

    const GalleryItems = (items) => (
        currentItems.map(item => (
            <motion.div
                key={item.dna}

                variants={item_anime}
                className="nft-item">
                <div className="nft-img-wrapper">
                    {/* <img src={`https://ipfs.io/ipfs/${IMAGE_CID}/${item.edition}.png`} alt={item.id} /> */}

                    <LazyLoadImage
                        width={`100%`}
                        height={`100%`}
                        alt={item.id}
                        placeholderSrc={'/images/skull.webp'}
                        src={`https://ipfs.io/ipfs/${IMAGE_CID}/${item.edition}.png`} />

                    {/* <img src={`${IPFS_GATEWAY}${item.edition}.png`} alt={item.id} /> */}
                </div>
                <div className="nft-detail-wrapper">
                    <h1>Zugaikotsu</h1>
                    <h3>No. #{item.edition}</h3>
                </div>

            </motion.div>
        ))
    );



    return (
        <>
            <motion.div

                ref={ref}
                id="Gallery" className="gallery-section">



                <div className="gallery-header-wrapper mb-100">

                    <div className="left-wrapper">
                        <img src="/images/titles/gallery.svg" className="gallery-title" alt="Gallery" />
                    </div>
                    <div className="right-wrapper">
                        <p>Our goal is to create the first IRL DAO created by you, governed by you. </p>
                    </div>
                </div>


                {(ready) ? (
                    <>
                        <div className="gallery-filter-wrapper mb-50">
                            <div className="main-filter-wrapper mb-10">
                                {(filter && filter.length) &&
                                    <>
                                        {
                                            filter.map((item, ind) => (
                                                <button key={ind} className={(parentQuery && (item.trait_type === parentQuery.trait_type)) ? 'primary-btn active' : 'primary-btn'} onClick={() => setParentQuery(item)}>{item.trait_type}</button>
                                            ))
                                        }
                                    </>
                                }
                            </div>
                            <div className="sub-filter-wrapper">
                                {(activeSubFilter) &&
                                    <>
                                        {
                                            activeSubFilter.map((item, ind) => (
                                                <button key={ind} className={(childQuery && (item === childQuery)) ? 'black-btn active' : 'black-btn'} onClick={() => filterBy(item)}>{item}</button>
                                            ))
                                        }
                                    </>
                                }
                            </div>


                        </div>

                        {(currentItems && currentItems.length) ?
                            <motion.div
                                variants={list_anime}
                                className="gallery-body-wrapper">

                                <motion.div
                                    variants={list_anime}
                                    className="gallery-list-wrapper">
                                    <GalleryItems currentItems={currentItems} />
                                </motion.div>

                                <motion.div className="gallery-paginate-wrapper">

                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="next >"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                        previousLabel="< previous"
                                        renderOnZeroPageCount={null}
                                    />
                                </motion.div>

                            </motion.div>
                            : <Loader />

                        }
                    </>
                ) :

                    <div className="gallery-placeholder-wrapper">
                        <h2>Comming Soon..</h2>
                        <p className="def-txt mb-30">Available after pre-sale.</p>
                        <Link to={'/'} className="primary-btn" >
                            Back Home
                        </Link>
                    </div>
                }






            </motion.div>
        </>
    );
}

export default Gallery;
