export const fetchGallery = async () => {
    try {
        const galleryResponse = await fetch("/contract/_metadata.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        });
        const gallery = await galleryResponse.json();
        return gallery;
    } catch (e) {
        return null;
    }
};
export const fetchFilter = async () => {
    try {
        const filterResponse = await fetch("/contract/filter.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        });
        const filter = await filterResponse.json();
        return filter;
    } catch (e) {
        return null;
    }
};